<template>
  <v-app
    id="login"
    :style="`background-image: linear-gradient(${colorPrimary}, ${colorPrimary}), url('${appLoginBackground}')`"
  >
    <v-main>
      <v-app-bar app color="primary" fixed dark clipped-left elevate-on-scroll>
        <router-link to="/" class="ml-2 mr-3 hidden-sm-and-down">
          <img v-if="!isDocline" :src="appLogoSecondary" :class="appMenuLogoClass" style="max-height: 32px" />
        </router-link>
      </v-app-bar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm6 md4 style="max-width: 500px; min-width: 320px">
            <div class="text-center bg-gradient">
              <v-card class="elevation-1 pa-3 card-reset-password">
                <v-card-text>
                  <div class="layout column align-center">
                    <img :src="appLogoPrimary" :alt="appDisplayName" width="80%" style="margin-bottom: 20px" />
                  </div>

                  <v-form>
                    <p class="mb-2 text-left">{{ $t('message.reset_password') }}:</p>
                    <v-text-field
                      v-model="password"
                      :rules="passwordRules"
                      type="password"
                      name="password"
                      append-icon="mdi-lock"
                      :label="$t('message.password')"
                    />

                    <v-text-field
                      v-model="password_reset"
                      type="password"
                      name="password-repeat"
                      append-icon="mdi-lock"
                      :label="$t('user.password_repeat')"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-btn block color="primary" :loading="loading" @click="resetPassword">
                    {{ $t('message.restaurar_password') }}
                  </v-btn>
                </v-card-actions>

                <v-card-actions>
                  <v-spacer />
                  <v-spacer />
                  <a @click="backToLogin">
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                    {{ $t('common.volver_atras') }}
                  </a>
                </v-card-actions>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { resetPasswordUrl } from '../config/config';

export default {
  data() {
    return {
      loading: true,
      password: '',
      password_reset: '',
      colorPrimary: '#1976D2',
      passwordRules: [
        v => this.containsLowercase(v || '') || this.$t('user.password_must_contain_lowercase'),
        v => this.containsUppercase(v || '') || this.$t('user.password_must_contain_uppercase'),
        v => this.containsNumbers(v || '') || this.$t('user.password_must_contain_numbers'),
        v => this.containsSymbols(v || '') || this.$t('user.password_must_contain_symbols'),
        v => (v && v.length >= 8) || this.$t('user.password_min_length', { num: 8 }),
      ],
    };
  },

  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    ...mapState({
      appLogoPrimary: state => state.app.images.logoPrimary,
      appDisplayName: state => state.app.displayName,
      appLoginBackground: state => state.app.images.loginBackground,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoBackground: state => state.app.styles.menuLogoBackground,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appThemePrimary: state => state.app.styles.themePrimary,
      appThemeSecondary: state => state.app.styles.themeSecondary,
      appName: state => state.app.name,
    }),
    isDocline() {
      //TODO:Crear mejor metodo
      return /^docline/.test(this.appName);
    },
  },

  created() {
    this.colorPrimary = this.$vuetify.theme.primary + 'eb';

    this.checkToken(this.$route.params.token, this.$route.params.email);
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    backToLogin() {
      this.$router.push('/login');
    },

    resetPassword() {
      if (!this.loading && this.validateData(this.password, this.password_reset)) {
        this.sendData(this.$route.params.token, this.$route.params.email, this.password);
      }
    },

    checkToken(token, email) {
      const data = {
        accion: 'index',
        token,
        email,
      };

      this.$http
        .post(resetPasswordUrl, data)
        .catch(e => {
          this.showAlert({
            color: 'warning',
            icon: 'mdi-lock',
            message: this.$t(e.data.error),
          });
          this.backToLogin();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateData(password, password_reset) {
      let error = null;

      if (password.trim() === '') {
        error = this.$t('errors.password_required');
      } else if (password !== password_reset) {
        error = this.$t('user.password_no_igual');
      } else if (password_reset.length < 8) {
        error = this.$t('user.password_min_length', { num: 8 });
      } else if (
        !(
          this.containsLowercase(password) &&
          this.containsUppercase(password) &&
          this.containsNumbers(password) &&
          this.containsSymbols(password)
        )
      ) {
        error = this.$t('user.invalid_password');
      }

      if (error) {
        this.showAlert({
          color: 'warning',
          icon: 'mdi-lock',
          message: error,
        });

        return false;
      } else {
        return true;
      }
    },

    sendData(token, email, password) {
      this.loading = true;

      const data = {
        accion: 'resetPassword',
        token,
        email,
        password,
      };

      this.$http
        .post(resetPasswordUrl, data)
        .then(response => {
          if (response.data === '1') {
            this.showAlert({
              color: 'green',
              message: this.$t('success.password_changed'),
            });
            this.backToLogin();
          } else {
            this.showAlert({
              color: 'error',
              icon: 'mdi-lock',
              message: this.$t('errors.try_again'),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    containsLowercase(str) {
      return !!str.match(/[a-z]/);
    },
    containsUppercase(str) {
      return !!str.match(/[A-Z]/);
    },
    containsNumbers(str) {
      return !!str.match(/\d/);
    },
    containsSymbols(str) {
      return !!str.match(/\W/);
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}

.bg-gradient {
  background: var(--v-secondary-base);
  background: linear-gradient(114deg, var(--v-primary-base) 20%, var(--v-secondary-base) 100%);
  border-radius: 10px;
  padding: 1px 30px 30px 30px !important;
  max-width: 790px;
}

.card-reset-password {
  margin-top: -32px;
}
</style>
